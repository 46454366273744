import { post } from '@/utils/request';

// 获取打卡列表
const getStatisticsReq = (params) => post({
  url: '/store/oa.AttendanceStatistics/statistics',
  data: {
    ...params,
  },
});
// 打卡数据导出
const statisticsExport = (data) => post({
  url: '/store/oa.AttendanceStatistics/statisticsExport',
  responseType: 'blob',
  data: data
})

// 获取跟进人数据
const selectUser = (params) => post({
  url: '/store/common.select/selectUser',
  data: {
    ...params,
  },
});


export {
  getStatisticsReq,
  statisticsExport,
  selectUser
};
