<template>
  <div>
    <div class="card" style='display: flex;'>
      <div class="left">
         <el-button
              type="primary"
              plain
              icon="el-icon-upload2"
              :loading="exportLoading"
              @click="handleExport()"
              >导出</el-button
            >
      </div>
      <div class='line'></div>
      <div class="middle">
        <el-form :model="form" :inline="true">
          <el-form-item label="时间">
            <el-date-picker
            @change="this.getList"
              v-model="form.create_time"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="门店" v-if="merchantList.length==1">
            <el-select v-model="form.hierarchy_id" placeholder="请选择门店" disabled  @change="this.getList">
              <template v-for="item in merchantList">
                <el-option :label="item.hierarchy_name" :value="item.hierarchy_id" :key="item.hierarchy_id"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="门店" v-else>
            <el-select v-model="form.hierarchy_id" placeholder="请选择门店" multiple filterable collapse-tags clearable  @change="this.getList">
              <template v-for="item in merchantList">
                <el-option :label="item.hierarchy_name" :value="item.hierarchy_id" :key="item.hierarchy_id"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="form.status" placeholder="请选择状态" clearable filterable  @change="this.getList">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="未校准" :value="10"></el-option>
               <el-option label="正常" :value="20"></el-option>
              <el-option label="异常" :value="30"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名" >
             <el-select v-model="form.user_name" filterable placeholder="请选择查询的姓名" @change="this.getList">
              <el-option
                v-for="item in options"
                :key="item.store_user_id"
                :label="item.realname"
                :value="item.realname">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button  @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="table">
      <el-table
        :data="tableData.list"
        style="width: 100%"
        v-loading="loading"
        height="700"
        stripe
      >
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width">
            <template slot-scope="scope">
              {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        background
        :current-page="page"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="$store.state.pageSizes"
        :page-size="$store.state.pageSizes[0]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total">
      </el-pagination>
    </div>
    <select-department :dialogVisible="dialogVisible" @onHideDepartment="handleHideDepartment" @onSelect="handleSelect" />
  </div>
</template>

<script>
import moment from 'moment';
import { getMerchantListReq } from '@/api/authority/config';
import { getStatisticsReq,statisticsExport,selectUser} from '@/api/work/clockIn';
import SelectDepartment from '../SelectDepartment.vue';

export default {
  data() {
    return {
      page: 1,
      page_num: this.$store.state.pageSizes[0],
      loading: false,
      merchantList: [], // 门店列表
      label: '',
      dialogVisible: false,
      form: {
        create_time:[]

      },
      exportLoading: false,
      rowList: [
        { field_text: '序号', field_alias: 'index' },
        { field_text: '时间', field_alias: 'work_date', t_width: 200 },
        { field_text: '姓名', field_alias: 'realname' },
        { field_text: '门店', field_alias: 'hierarchy_id_text' },
        { field_text: '所属规则', field_alias: 'rule_name' },
        { field_text: '最早打卡', field_alias: 'first_attendance' },
        { field_text: '最晚打卡', field_alias: 'end_attendance' },
        { field_text: '打卡次数', field_alias: 'attendance_count' },
        { field_text: '工作时长', field_alias: 'reality_work_time' },
        // { field_text: '原始状态', field_alias: 'order_number1' },
        { field_text: '校准状态', field_alias: 'calibration_state' },
        { field_text: '假勤申请', field_alias: 'holiday_detail' },
        { field_text: '异常合计', field_alias: 'exception_total_count' },
        { field_text: '迟到次数', field_alias: 'late_count' },
        { field_text: '迟到时长', field_alias: 'late_time' },
        { field_text: '早退次数', field_alias: 'leave_count' },
        { field_text: '早退时长', field_alias: 'leave_time' },
        { field_text: '旷工次数', field_alias: 'absence_count' },
        { field_text: '旷工时长', field_alias: 'absence_time' },
        { field_text: '地点异常', field_alias: 'place_exception_count' },
      ],
      tableData: {
        list: [],
        total: 0,
      },
      options:[]
    };
  },
  components: {
    SelectDepartment,
  },
  created() {
    // 获取门店列表
    this.form.create_time[1]=moment().format("YYYY-MM-DD");
    this.form.create_time[0]=moment().subtract(30, "days").format("YYYY-MM-DD");
    this.getMerchantList();
    this.getList();
    this.selectUser();
  },
  methods: {
    resetForm() {
      this.form = {
        create_time:[]
      };
      if(this.merchantList.length==1){
        this.form.hierarchy_id=this.merchantList[0].hierarchy_id;
      }
      this.form.create_time[1]=moment().format("YYYY-MM-DD");
      this.form.create_time[0]=moment().subtract(30, "days").format("YYYY-MM-DD");
      this.page = 1;
      // this.limit = 15;
      this.getList();
    },//获取跟进人列表
    selectUser(){
        selectUser().then((res) => {
          if (res.code === 1) {
            this.options=res.data;
          }
        })
    },
    // 获取打卡列表
    getList() {
      this.loading = true;
      let data=JSON.parse(JSON.stringify(this.form));
      if(data.user_name=="全部"){
        data.user_name="";
      }
      getStatisticsReq({ ...data, page: this.page, page_num: this.page_num })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.page = 1;
      this.getList();
    },
    getMerchantList() {
      getMerchantListReq()
        .then((res) => {;
          if (res.code === 1) {
            this.merchantList = res.data;
            if(res.data.length==1){
              this.form.hierarchy_id=res.data[0].hierarchy_id;
            }
          }
        });
    },
    // 选择部门子组件回调事件
    handleSelect(nodeList) {
      console.log(nodeList);
      if (nodeList.length === 1) {
        this.label = nodeList[0].label;
      } else {
        this.label = `${nodeList[0].label}等多个部门`;
      }
      this.form.select = 1;
    },
    handleHideDepartment() {
      this.dialogVisible = false;
    },
    // 分页页数改变
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    // 分页条数改变
    handleSizeChange(page_num) {
      this.page = 1;
      this.page_num = page_num;
      this.getList();
    },
    // 打卡导出
    handleExport() {
       this.exportLoading = true;
      if(this.form.create_time!=undefined&&this.form.create_time!=""){
        if(moment(this.form.create_time[1]).diff(moment(this.form.create_time[0]),'days')<62){
            statisticsExport({
            ...this.form
              })
              .then((res) => {
                this.exportLoading = false;
                const blob = new Blob([res], { type: "application/vnd.ms-excel" });
                const a = document.createElement("a");
                a.setAttribute("download", `打卡统计.xlsx`);
                a.href = URL.createObjectURL(blob);
                a.click();
              })
              .catch(() => {
                this.exportLoading = false;
              });
        }else{
          this.exportLoading = false;
          this.$message({
          type: "warning",
          message: "导出时间不能大于两个月",
        });
        }

      }else{
        this.exportLoading = false;
        this.$message({
          type: "warning",
          message: "请选择导出时间",
        });
      }
    },
    // 弹窗关闭
    handleClose() {},
    // 选择部门
    handleChoose() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.middle {
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
}
</style>
